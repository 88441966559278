import permisosStore from './permisos.store'
import { createStore } from 'vuex'
import UsuariosService from '../services/usuarios.service'
import LocalizacionService from '../services/localizacion.service'
import SedesService from '../services/sedes.service'
import AreasService from '../services/areas.service'
import CargosService from '../services/cargos.service'

const _LocalizacionService = new LocalizacionService()
const _UsuariosService = new UsuariosService()
const _SedesService = new SedesService()
const _AreasService = new AreasService()
const _CargosService = new CargosService()

const usuariosStore = {
  state: {
    usuarios: [],
    usuario: {},
    ubicacion: {},
    usuario_persona: {},
    usuario_grupo: {},
    filtro_tabla_usuarios_nombre: '',
    filtro_tabla_grupos: '',
    guardar_usuario: 0,
    type_documents: [],
    usuario_data: {
      firt_name: '',
      last_name: '',
      user_name: '',
      state: false,
      email: '',
      password: '',
      confirmation_password: '',
      type_document_id: 'CC',
      number_document: '',
      phone: '',
      localizacion: 0,
      direccion: '',
      persona_id: 0,
      foto_perfil: {},
      grupo_id: 0,
      actualizadoPor: '',
      actualizadoALas: ''
    },
    imagen: '',
    usuario_group: 0,
    editar: false,
    localizaciones: [],
    listado_servicios: [],
    listado_menus_ppales: [],
    listado_todos_grupos: [],
    data_empleado: {},
    empleado: false,
    sedes: [],
    areas: [],
    cargos: []
  },
  getters: {
    _usuarios: (state) => state.usuarios,
    _usuario: (state) => state.usuario,
    _usuario_persona: (state) => state.usuario_persona,
    _usuario_grupo: (state) => state.usuario_grupo,
    _filtro_tabla_usuarios_nombre: (state) => state.filtro_tabla_usuarios_nombre,
    _filtro_tabla_grupos: (state) => state.filtro_tabla_grupos,
    _guardar_usuario: (state) => state.guardar_usuario,
    _type_documents: (state) => state.type_documents,
    _permisos: (state) => state.permisos,
    _usuarioPost: (state) => state.usuarioPost,
    _editar: (state) => state.editar,
    _usuario_data: (state) => state.usuario_data,
    _usuario_group: (state) => state.usuario_group,
    _imagen: (state) => state.imagen,
    _localizaciones: (state) => state.localizaciones,
    _listado_servicios: (state) => state.listado_servicios,
    _ubicacion: (state) => state.ubicacion,
    _listado_menus_ppales: (state) => state.listado_menus_ppales,
    _listado_todos_grupos: (state) => state.listado_todos_grupos,
    _data_empleado: (state) => state.data_empleado,
    _empleado: (state) => state.empleado,
    _sedes: (state) => state.sedes,
    _areas: (state) => state.areas,
    _cargos: (state) => state.cargos
  },
  mutations: {
    setUsuarios (state, payload) {
      state.usuarios = payload
    },
    setUsuario (state, payload) {
      state.usuario = payload
    },
    setUbicacion (state, payload) {
      state.ubicacion = payload
    },
    setUsuarioPersona (state, payload) {
      state.usuario_persona = payload
    },
    setUsuarioGrupo (state, payload) {
      state.usuario_grupo = payload
    },
    setFiltroPorNombreTabla (state, payload) {
      state.filtro_tabla_usuarios_nombre = payload
    },
    setFiltroGrupo (state, payload) {
      state.filtro_tabla_grupos = payload
    },
    setDocumentsTypes (state, payload) {
      state.type_documents = payload
    },
    setEditar (state, payload) {
      state.editar = payload
    },
    setUsuarioData (state, payload) {
      state.usuario_data.firt_name = payload.firt_name
      state.usuario_data.last_name = payload.last_name
      state.usuario_data.user_name = payload.user_name
      state.usuario_data.state = payload.state
      state.usuario_data.email = payload.email
      state.usuario_data.password = payload.password
      state.usuario_data.type_document_id = payload.type_document_id
      state.usuario_data.number_document = payload.number_document
      state.usuario_data.phone = payload.phone
      state.usuario_data.localizacion = payload.localizacion ? payload.localizacion : 0
      state.usuario_data.direccion = payload.direccion
      state.usuario_data.foto_perfil = payload.foto_perfil
      state.usuario_data.persona_id = payload.persona_id
      state.usuario_data.grupo_id = payload.grupo_id
      state.usuario_data.confirmation_password = payload.confirmation_password
      state.usuario_data.actualizadoPor = payload.actualizadoPor ? payload.actualizadoPor : ''
      state.usuario_data.actualizadoALas = payload.actualizadoALas ? payload.actualizadoALas : ''
    },
    setImagenPerfil (state, payload) {
      state.imagen = payload
    },
    limpiarUsuarioData (state) {
      state.usuario_data.firt_name = ''
      state.usuario_data.last_name = ''
      state.usuario_data.user_name = ''
      state.usuario_data.state = false
      state.usuario_data.email = ''
      state.usuario_data.password = ''
      state.usuario_data.type_document_id = 'CC'
      state.usuario_data.number_document = ''
      state.usuario_data.phone = ''
      state.usuario_data.localizacion = 0
      state.usuario_data.direccion = ''
      state.usuario_data.foto_perfil = {}
      state.usuario_data.confirmation_password = ''
      state.grupo_id = 0
      state.usuario_data.persona_id = 0
      state.imagen = ''
      state.usuario_data.actualizadoPor = ''
      state.usuario_data.actualizadoALas = ''
      permisosStore.commit('limpiarData')
    },
    setLocalizaciones (state, payload) {
      state.localizaciones = payload
    },
    setListadoServicios (state, payload) {
      state.listado_servicios = payload
    },
    setListadoMenusPpales (state, payload) {
      state.listado_menus_ppales = payload
    },
    setTodosGrupos (state, payload) {
      state.listado_todos_grupos = payload
    },
    setEsEmpleado (state, payload) {
      state.empleado = payload
    },
    setDataEmpleado (state, payload) {
      state.data_empleado = payload
    },
    setSedes (state, payload) {
      state.sedes = payload
    },
    setAreas (state, payload) {
      state.areas = payload
    },
    setCargos (state, payload) {
      state.cargos = payload
    }
  },
  actions: {
    getUsuarios ({ commit }, payload) {
      _UsuariosService.paginate(payload).then(({ data }) => {
        const array = []
        if (data.rows.length) {
          for (const i of data.rows) {
            if (array.length) {
              if (!array.some(a => a === i.persona.full_name)) {
                array.push({
                  name: i.persona.full_name
                })
              }
              if (!array.some(a => a === i.user_name)) {
                array.push({
                  name: i.user_name
                })
              }
            } else {
              array.push({
                name: i.persona.full_name
              })
              array.push({
                name: i.user_name
              })
            }
          }
        }
        commit('setUsuarios', array)
      })
    },
    find ({ commit, dispatch }, id) {
      return _UsuariosService.find(id).then(({ data }) => {
        dispatch('listarLocalizaciones', data.persona.localizacion)
        permisosStore.commit('setGrupoSelecionado', data.grupo.id)
        commit('setUsuario', data)
        commit('setUsuarioPersona', data.persona)
        commit('setUsuarioGrupo', data.grupo)
        if (data.persona.empleado) {
          commit('setEsEmpleado', true)
          const empleadoInfo = {
            sede: data.persona.empleado.contratos.estacion_trabajo.branch_office_id,
            area: data.persona.empleado.contratos.estacion_trabajo.area_id,
            cargo: data.persona.empleado.Cargo
          }
          commit('setDataEmpleado', empleadoInfo)
        } else {
          commit('setEsEmpleado', false)
          const empleadoInfo = {
            sede: '',
            area: '',
            cargo: ''
          }
          commit('setDataEmpleado', empleadoInfo)
        }
      })
    },
    listarLocalizaciones ({ commit }, id) {
      _LocalizacionService.paginate().then(({ data }) => {
        commit('setUbicacion', data.filter(a => parseInt(a.codMpio) === parseInt(id))[0])
      })
    },
    filtroPorNombreTabla ({ commit }, payload) {
      commit('setFiltroPorNombreTabla', payload)
    },
    filtroPorGrupo ({ commit }, payload) {
      commit('setFiltroGrupo', payload)
    },
    post ({ commit }, payload) {
      return _UsuariosService.post(payload).then((response) => {
        return response
      })
    },
    put ({ commit }, payload) {
      return _UsuariosService.put(payload).then((response) => {
        return response
      })
    },
    getDocumentTypes ({ commit }) {
      _UsuariosService.typeDocument().then(({ data }) => {
        commit('setDocumentsTypes', data)
      })
    },
    obtenerSedes ({ commit }) {
      _SedesService.paginate().then(({ data }) => {
        commit('setSedes', data)
      })
    },
    obtenerAreas ({ commit }) {
      _AreasService.paginate().then(({ data }) => {
        const array = data.filter(a => a.parent_area_id)
        for (const i of array) {
          i.name = data.find(a => a.id === i.parent_area_id).name + ' - ' + i.name
        }
        commit('setAreas', array)
      })
    },
    obtenerCargos ({ commit }) {
      _CargosService.paginate().then(({ data }) => {
        commit('setCargos', data)
      })
    }
  }
}
const store = createStore(usuariosStore)
export default {
  namespaced: true,
  ...store
}
