import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class GruposService {
  paginate (params) {
    return http.get(`${baseUrl}/grupos/listar-grupos/`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getall () {
    return http.get(`${baseUrl}/grupos/listar-grupos-todos/`, {
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/grupos/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  post (payload) {
    return http.post(`${baseUrl}/grupos`, payload, {
      headers: {
        loading: true
      }
    })
  }

  put (payload) {
    return http.put(`${baseUrl}/grupos/editar-grupo`, payload, {
      headers: {
        loading: true
      }
    })
  }
}
