import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class LocalizacionService {
  paginate () {
    return http.get(`${baseUrl}/localizacion/listar`, {
      headers: {
        loading: true
      }
    })
  }
}
