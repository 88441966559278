import http from '@/libs/http'
import axios from 'axios'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class UsuariosService {
  paginate (params) {
    return http.get(`${baseUrl}/listar/`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  findByCC (cc) {
    return http.get(`${baseUrl}/findByCC/${cc}`, {
    })
  }

  typeDocument () {
    return http.get(`${baseUrl}/type-document`, {
      headers: {
        loading: true
      }
    })
  }

  getByGroup (params) {
    return http.get(`${baseUrl}/getbygroup`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  ver (params) {
    const url = `${baseUrl}/ver-imagen`
    const res = axios.getUri({
      url,
      params
    })
    console.log({ res })
    window.open(res, '_blank')
  }

  post (params) {
    return http.post(`${baseUrl}`, params, {
      headers: {
        loading: true
      }
    })
  }

  updateAll (params) {
    return http.put(`${baseUrl}/editar-estados-masivos`, params, {
      headers: {
        loading: true
      }
    })
  }

  put (params) {
    return http.put(`${baseUrl}/editar`, params, {
      headers: {
        loading: true
      }
    })
  }
}
