import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class PermisosService {
  paginate () {
    return http.get(`${baseUrl}/permisos/listar/`, {
      headers: {
        loading: true
      }
    })
  }

  paginateActive () {
    return http.get(`${baseUrl}/permisos/listarActives`, {
      headers: {
        loading: true
      }
    })
  }

  permisosGrupo (params) {
    return http.get(`${baseUrl}/permisos/permisos-grupo`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  permisosPersona (params) {
    return http.get(`${baseUrl}/permisos/permisos-persona`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  postPermisosGrupo (payload) {
    return http.post(`${baseUrl}/permisos/activar-permisos/grupos`, payload, {
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/permisos/encontrar-permiso-por-id/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  paginarPermisos (params) {
    return http.get(`${baseUrl}/permisos/paginar-permisos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  search (global) {
    return http.get(`${baseUrl}/permisos/search-permiso/${global}`, {
      headers: {
        loading: true
      }
    })
  }

  editar (params) {
    return http.put(`${baseUrl}/permisos/editar`, params, {
      headers: {
        loading: true
      }
    })
  }

  crear (params) {
    return http.post(`${baseUrl}/permisos/crear`, params, {
      headers: {
        loading: true
      }
    })
  }
}
