import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class SedesService {
  paginate () {
    return http.get(`${baseUrl}/sedes/listar-sedes`, {
      headers: {
        loading: true
      }
    })
  }
}
