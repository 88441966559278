import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_USUARIOS

export default class Services {
  paginate () {
    return http.get(`${baseUrl}/services/listar-servicios/`, {
      headers: {
        loading: true
      }
    })
  }

  todos () {
    return http.get(`${baseUrl}/services/listar-servicios-todos/`, {
      headers: {
        loading: true
      }
    })
  }

  search (name) {
    return http.get(`${baseUrl}/services/search-servicio/${name}`, {
      headers: {
        loading: true
      }
    })
  }
}
