import { createStore } from 'vuex'
import GruposService from '../services/grupos.service'
import Services from '../services/services.service'
import PermisosService from '../services/permisos.service'
const _GruposService = new GruposService()
const _PermisosService = new PermisosService()
const _Services = new Services()
const permisosStore = {
  state: {
    permisosActivos: [],
    grupoSelecionado: 1,
    permisosGuardar: [],
    listadoGrupos: [],
    permisosPorGrupo: [],
    listadoServicios: []
  },
  getters: {
    _permisosActivos: (state) => state.permisosActivos,
    _grupoSelecionado: (state) => state.grupoSelecionado,
    _permisosGuardar: (state) => state.permisosGuardar,
    _listadoGrupos: (state) => state.listadoGrupos,
    _permisosPorGrupo: (state) => state.permisosPorGrupo,
    _listadoServicios: (state) => state.listadoServicios
  },
  mutations: {
    setPermisosActivos (state, payload) {
      state.permisosActivos = payload
    },
    setGrupoSelecionado (state, payload) {
      state.grupoSelecionado = payload
    },
    setPermisosGuardar (state, payload) {
      state.permisosGuardar = payload
    },
    setGrupos (state, payload) {
      state.listadoGrupos = payload
    },
    setPermisosPorGrupo (state, payload) {
      state.permisosPorGrupo = payload
    },
    limpiarData (state) {
      state.permisosPorGrupo = []
      state.grupoSelecionado = 1
      state.permisosActivos = []
    },
    setListadoServicios (state, payload) {
      state.listadoServicios = payload
    }
  },
  actions: {
    listadoGrupos ({ commit, state }) {
      return new Promise((resolve) => {
        _GruposService.getall().then(({ data }) => {
          if (state.grupoSelecionado === 0) {
            commit('setGrupoSelecionado', 1)
          }
          commit('setGrupos', data)
          resolve()
        })
      })
    },
    listarPermisosPorGrupo ({ commit, state, dispatch }) {
      return new Promise((resolve) => {
        _PermisosService.permisosGrupo({ id: state.grupoSelecionado }).then(({ data }) => {
          commit('setPermisosActivos', data)
          dispatch('getServicios')
          resolve()
        })
      })
    },
    validarPermisosIndividuales ({ commit, state, dispatch }, payload) {
      _PermisosService.permisosPersona({ id: payload }).then(({ data }) => {
        const array = []
        array.push(...state.permisosActivos)
        if (data.length) {
          for (const i of state.permisosActivos) {
            for (const j of data) {
              if (j.permission_action === i.permission_action) {
                i.state = j.state
              } else {
                if (j.state) {
                  array.push(j)
                }
              }
            }
          }
        }
        commit('setPermisosActivos', array)
        dispatch('getServicios')
      })
    },
    getServicios ({ commit, state }) {
      const listadoPermisosActivos = state.permisosActivos
      let listadoServicios = []
      const activarPermiso = (permiso) => {
        if (permiso.per.length) {
          if (permiso.per.some(a => a.autorizacion === 1)) {
            if (permiso.per.some(a => a.autorizacion === 2 || a.autorizacion === 3)) {
              permiso.autorizacion = 2
            } else {
              permiso.autorizacion = 1
            }
          }
          if (permiso.per.some(a => a.autorizacion === 2)) {
            permiso.autorizacion = 2
          }
          if (permiso.per.some(a => a.autorizacion === 3)) {
            if (permiso.per.some(a => a.action.includes('access'))) {
              if (listadoPermisosActivos.some(a => a.permission_action === permiso.action && a.state === true)) {
                permiso.autorizacion = 2
              } else {
                permiso.autorizacion = 3
              }
            } else {
              if (permiso.per.some(a => a.autorizacion === 1 || a.autorizacion === 2)) {
                permiso.autorizacion = 2
              } else {
                permiso.autorizacion = 3
              }
            }
          }
        } else {
          if (listadoPermisosActivos.some(a => a.permission_action === permiso.action && a.state === true)) {
            permiso.autorizacion = 1
          } else {
            permiso.autorizacion = 3
          }
        }
      }
      const permisosGuardar = () => {
        const array = []
        const saveData = (data) => {
          array.push({
            permission_action: data.action,
            state: true,
            type: 'SecurityGroups',
            base: state.grupoSelecionado
          })
        }
        const recorrer = (padre) => {
          if (padre.autorizacion === 1 || padre.autorizacion === 2) {
            saveData(padre)
            if (padre.per.length) {
              for (const hijo of padre.per) {
                recorrer(hijo)
              }
            }
          }
        }
        for (const i of listadoServicios) {
          for (const j of i) {
            recorrer(j)
          }
        }
        commit('setPermisosGuardar', array)
        commit('setListadoServicios', listadoServicios)
      }
      _Services.paginate().then(({ data }) => {
        const array = []
        for (let servicio of data) {
          servicio = {
            ...servicio,
            autorizacion: 3,
            open: false
          }
          const recorrer = (padre) => {
            if (padre.per.length) {
              for (const hijo of padre.per) {
                recorrer(hijo)
                hijo.open = false
                activarPermiso(hijo)
              }
            }
          }
          recorrer(servicio)
          activarPermiso(servicio)
          array.push(servicio)
        }
        const mitad = Math.floor(array.length / 2)
        const arraySend = []
        arraySend[0] = mitad % 2 === 0 ? array.splice(0, mitad + 1) : array.splice(0, mitad)
        arraySend[1] = array
        listadoServicios = arraySend
        permisosGuardar()
      })
    }
  }
}
const store = createStore(permisosStore)
export default {
  namespaced: true,
  ...store
}
